<template>
  <v-container class="cryptomation-container">
    <h1 class="mb-1">{{ $t("auth.changePassword") }}</h1>
    <v-sheet rounded class="pa-10">
      <v-alert color="red" type="error" v-if="formError">
        {{ messageError }}
      </v-alert>

      <!-- <v-alert color="green" type="success" v-if="passwordChaged">
        Your password has been changed
      </v-alert> -->
      <form>
        <v-text-field
          v-model="oldPassword"
          :error-messages="oldPasswordErrors"
          :label="$t('form.oldPassword')"
          required
          :disabled="formDisabled"
          type="password"
          @input="$v.oldPassword.$touch()"
          @blur="$v.oldPassword.$touch()"
          @keyup.enter="changePasswordAction"
          color="info"
        ></v-text-field>

        <v-text-field
          v-model="newPassword"
          :error-messages="passwordErrors"
          :label="$t('form.newPassword')"
          required
          type="password"
          :disabled="formDisabled"
          @input="$v.newPassword.$touch()"
          @blur="$v.newPassword.$touch()"
          @keyup.enter="changePasswordAction"
          color="info"
        ></v-text-field>

        <v-text-field
          v-model="confirmNewPassword"
          :error-messages="confirmPasswordErrors"
          :label="$t('form.confirmNewPassword')"
          required
          type="password"
          :disabled="formDisabled"
          @input="$v.confirmNewPassword.$touch()"
          @blur="$v.confirmNewPassword.$touch()"
          @keyup.enter="changePasswordAction"
          color="info"
        ></v-text-field>
        <v-btn
          class="mt-4"
          :disabled="formDisabled"
          :loading="formDisabled"
          color="success"
          block
          @click="changePasswordAction"
        >
          {{ $t("auth.changePassword") }}
        </v-btn>
      </form>
    </v-sheet>
  </v-container>
</template>

<script>
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { changePasswordAPI } from "@/services/api/user";
import { mapActions } from "vuex";
export default {
  name: "ChangePasswordPage",
  mixins: [validationMixin],
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      formError: false,
      messageError: "",
      formDisabled: false,
      // passwordChaged: false
    };
  },
  mounted() {},
  methods: {
    ...mapActions(["userLogout"]),
    async changePasswordAction() {
      this.messageError = "";
      this.formError = false;
      this.formDisabled = true;

      // this.userLogout("reset_password");

      if (!this.$v.$invalid) {
        let res_data = await changePasswordAPI({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });

        if (res_data["code"] === 200) {
          // this.passwordChaged = true;
          this.userLogout("reset_password");
          // rediction to signout success
        } else if (res_data["code"] === 400) {
          if (res_data["message"] === "INVALID_PASSWORD") {
            this.messageError = this.$t("error.auth.oldPasswordIsInvalid");
          } else {
            this.messageError = res_data["message"];
          }
          this.formError = true;
        } else {
          this.messageError = this.$t("error.auth.canotConnectServer");
          this.formError = true;
        }
      } else {
        this.$v.$touch();
      }
      this.formDisabled = false;
    },
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      !this.$v.oldPassword.minLength &&
        errors.push(this.$t("error.auth.oldPasswordLessThanSix"));
      !this.$v.oldPassword.required &&
        errors.push(this.$t("error.auth.oldPasswordRequired"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.minLength &&
        errors.push(this.$t("error.auth.newPasswordLessThanSix"));
      !this.$v.newPassword.required &&
        errors.push(this.$t("error.auth.newPasswordRequired"));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmNewPassword.$dirty) return errors;
      !this.$v.confirmNewPassword.sameAsPassword &&
        errors.push(this.$t("error.auth.confirmNewPassPasswordNotMatching"));
      return errors;
    },
  },
  validations: {
    oldPassword: { required, minLength: minLength(6) },
    newPassword: { required, minLength: minLength(6) },
    confirmNewPassword: { sameAsPassword: sameAs("newPassword") },
  },
};
</script>
